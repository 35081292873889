<template>
  <div class="page--pimple-pop finish bbb-pages">
    <div class="bbb-finish">
      <HeaderLogo />
      <!-- <img v-if="!contentForPrint" class="sociolla-logo" src="/static/img/logo-sociolla.svg" /> -->

      <template v-if="voucherCopywriting && allowed" />
      <template v-else>
        <!-- GAME OVER -->
        <div class="game-over">
          <div class="failed">You tried, Bestie!</div>
        </div>
      </template>

      <template v-if="isVnSource">
        <div v-if="voucherCopywriting && allowed" class="box-congrats" :class="{ 'box-congrats-vn': isVnSource }">
          <h2 class="box-congrats__heading vn">CHÚC MỪNG BẠN!</h2>

          <span class="box-congrats__text vn">BẠN NHẬN ĐƯỢC:</span>

          <div>
            <div v-if="isVnSource" class="col-sm-6 col score" :class="{ 'score-vn': isVnSource }">
              <span>{{ zits }}</span>
            </div>
            <div v-else class="col-sm-6 col score">
              <span>{{ zits }}</span>
            </div>

            <div v-if="!isVoucherGenerated" class="col-sm-6 col coupon-sbs" style="margin-top: 80px">
              <span class="coupon__text-reward">
                <span class="coupon-sbs-title">{{ voucherCopywriting }}</span>
                <br />
                <img src="/static/img/soco-beauty-star/period-time-sbs.png" width="24px" />
                <span class="coupon-sbs-small"> Berlaku hingga {{ today }}</span>
              </span>
            </div>
          </div>

          <a href="" class="button-print-voucher" @contextmenu.prevent @click.prevent="toggleModalVoucher">
            <template v-if="!processing"> NHẬN NGAY! </template>
            <div v-else id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
          </a>
        </div>

        <div v-else class="box-congrats failed">
          <h2 class="sbs-text-title vn">ĐỪNG VỘI BỎ CUỘC BẠN NHÉ VÌ MÃ GIẢM GIÁ VẪN CÒN RẤT NHIỀU!</h2>
          <div v-if="isVnSource" class="score score-failed" :class="{ 'score-vn': isVnSource }">
            <p>{{ zits }}</p>
          </div>
          <div v-else class="score score-failed">
            <p>{{ zits }}</p>
          </div>
          <button class="button-print-voucher" @click="backHome()">QUAY LẠI MÀN HÌNH TRANG CHỦ</button>
        </div>
      </template>
      <template v-else>
        <div v-if="voucherCopywriting && allowed" class="box-congrats">
          <div class="sbs-text-title voucher-and-point-title">Yeay, you did it!</div>
          <p class="sbs-text-title voucher-and-point-subtitle">
            Voucher sudah dikirimkan melalui email. Klaim<br />voucher-mu dan belanja sekarang!
          </p>

          <div class="reward-container">
            <div v-if="!isVoucherGenerated" class="col-sm-6 col coupon-sbs" style="margin-top: 40px">
              <div class="coupon__reward">
                <span class="coupon-sbs-title">{{ voucherCopywriting }}</span>
                <div class="clock__box">
                  <img class="clock__position" src="/static/img/soco-beauty-star/period-time-sbs.png" width="24px" />
                  <span class="coupon-sbs-small"> *Tanpa minimum belanja </span>
                </div>
                <span class="coupon-sbs-small"> Berlaku hingga {{ today }}</span>
              </div>
            </div>

            <template v-if="isVoucherGenerated">
              <div v-if="isVnSource" class="col-sm-6 col score" :class="{ 'score-vn': isVnSource }">
                <p>{{ zits }}</p>
              </div>
              <div v-else class="col-sm-6 col score">
                <p>{{ zits }}</p>
              </div>
            </template>
          </div>

          <!-- <a href="" class="button-print-voucher black" @click.prevent="openModal">
            <template v-if="!processing"> PRINT VOUCHER </template>
            <div v-else id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
          </a> -->
          <!-- <a
            v-if="!isVoucherGenerated"
            :disabled="isEmailSent"
            href=""
            class="button-print-voucher black"
            @click.prevent="sendEmail()"
          >
            <template v-if="!processing">Kirim ke email</template>
            <div v-else id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
          </a> -->
          <a
            v-if="!isVoucherGenerated && !vendingMachineDevice"
            :disabled="isEmailSent"
            href=""
            class="button-print-voucher black"
            @click.prevent="goToShopping()"
          >
            <template v-if="!processing">Belanja sekarang</template>
            <div v-else id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
          </a>
          <button
            v-if="!processing && !vendingMachineDevice"
            class="button-browse-shop white"
            @click="postTracker('close')"
          >
            Keluar
          </button>
          <button v-else class="button-print-voucher" @click="postTracker('close')">Keluar</button>
        </div>

        <div v-else class="box-congrats failed">
          <h2 class="sbs-text-title">Meski belum dapat voucher,<br />kamu tetap dapat:</h2>
          <div v-if="isVnSource" class="score" :class="{ 'score-vn': isVnSource }">
            <p>{{ zits }}</p>
          </div>
          <div v-else class="score score-failed">
            <p>{{ zits }}</p>
          </div>
          <button v-if="!vendingMachineDevice" class="button-browse-shop" @click="goToShopping()">
            Belanja sekarang
          </button>
          <button class="button-print-voucher" @click="postTracker('close')">Keluar</button>
        </div>
      </template>

      <!-- <div class="sbs-finish-footer">
        <h2 class="sbs-text-title" style="color: #fff; padding: 30px 0 30px 0">Shop The Bestie Deals</h2>
        <Carousel
          :per-page="1"
          :pagination-enabled="false"
          :navigation-enabled="false"
          :loop="true"
          :navigation-next-label="''"
          :navigation-prev-label="''"
          :pagination-color="'#F5B0B3'"
          :pagination-active-color="'#FFFFFF'"
          :autoplay="isAutoPlay"
          :autoplay-timeout="4000"
        >
          <slide v-for="banner in footerBanner" :key="banner._id">
            <div class="item-slide">
              <img :src="banner.url" />
            </div>
          </slide>
        </Carousel>
      </div> -->

      <HomeBanners v-if="!contentForPrint" type="secondary" style="margin-top: 120px" />
    </div>
    <template v-if="isVnSource">
      <GModal
        v-show="isModalVoucherOpen"
        ref="modalFilterBrand"
        class="modal--full-fixed modal--print-voucher modal--print-voucher-dialog modal-printed"
      >
        <div slot="header"></div>
        <div slot="body" ref="modalBodyFilterBrand" class="content">
          <div class="voucher-printed">
            <img src="/static/img/icons/logo-sociolla.png" width="332" height="81" class="title-vn" />
            <h2 class="reward-vn">GIẢM {{ VoucherPrintCopyWrite }} TỔNG HOÁ ĐƠN</h2>
            <p class="minimum"><sup>*</sup>Tối đa {{ maximumDisc }} đ</p>
            <svg
              v-if="voucherCode"
              id="barcode"
              jsbarcode-format="CODE128"
              :jsbarcode-value="voucherCode"
              jsbarcode-textmargin="0"
              jsbarcode-fontoptions="bold"
              :jsbarcode-text="`MÃ CỦA BẠN: ${voucherCode}`"
              width="260"
            ></svg>
            <p class="greeting-vn">
              Vui lòng chụp màn hình này hoặc kiểm tra hộp thư điện tử <br />
              của bạn để lưu mã và mua sắm tại Sociolla.
            </p>
            <p class="greeting-vn">
              Mã giảm giá có giá trị đến hết ngày <b>{{ today }}</b> và chỉ áp <br />dụng cho khách hàng đã checkin.<br />
            </p>
            <p class="greeting-vn">Mỗi khách hàng chỉ được nhận 1 mã/ngày.</p>
            <button v-if="!vendingMachineDevice" class="button-browse-shop" @click="goToShopping()">
              Belanja sekarang
            </button>
            <button class="button-print-voucher" @click="backHome()">QUAY LẠI TRANG CHỦ</button>
          </div>
        </div>
      </GModal>
    </template>
    <template v-else>
      <GModal
        v-show="isModalPrintOpen"
        ref="modalFilterBrand"
        class="modal--full-fixed modal--print-voucher modal--print-voucher-dialog"
        :class="{ 'modal-printed': stateIsPrinted }"
      >
        <div slot="header"></div>
        <div slot="body" ref="modalBodyFilterBrand" class="content">
          <div v-if="!stateIsPrinted" class="print-voucher">
            <img src="/static/img/pimple-pop/printer@2x.png" width="477" />
            <p class="copywriting" style="color: #d51d6e">Voucher is printing...</p>
          </div>

          <div v-else class="voucher-printed">
            <img src="/static/img/pimple-pop/voucher-printed@2x-v2.png" width="542" />
            <h3 class="heading">VOUCHER PRINTED!</h3>
            <p class="greeting" style="color: #d51d6e">Terima kasih sudah menggunakan <br />SOCO Vending Machine</p>

            <button class="btn-back" @click="backHome()">BACK TO HOME SCREEN</button>
          </div>
        </div>
      </GModal>
    </template>

    <GModal ref="modalFilterBrand" class="modal--full-fixed modal--print-voucher modal-voucher">
      <div slot="header"></div>
      <div slot="body" ref="modalBodyFilterBrand" class="content">
        <div class="voucher-printed">
          <template v-if="isVnSource">
            <!-- <img src="/static/img/icons/sociolla.jpg" width="130" /> -->
            <h2 class="reward-vn">GIẢM {{ VoucherPrintCopyWrite }} TỔNG HOÁ ĐƠN</h2>
            <p class="minimum"><sup>*</sup>Tối đa 75.000 đ</p>
            <svg
              v-if="voucherCode"
              id="barcode"
              jsbarcode-format="CODE128"
              :jsbarcode-value="voucherCode"
              jsbarcode-textmargin="0"
              jsbarcode-fontoptions="bold"
              width="260"
            ></svg>
            <p class="greeting">
              Vui lòng chụp màn hình này hoặc kiểm tra hộp thư điện tử của bạn để lưu<br />
              mã và mua sắm tại Sociolla.
            </p>
            <p class="greeting">
              Mã giảm giá có giá trị đến hết ngày {{ today }} và chỉ áp <br />dụng cho khách hàng đã checkin.<br />
            </p>
          </template>
          <template v-else>
            <!-- <img src="/static/img/icons/sociolla.jpg" width="130" /> -->
            <h4 class="fullname">
              Hi <span class="bolder">{{ fullname }}</span>
            </h4>
            <p class="copywriting">Enjoy your Press Play voucher</p>
            <h2 class="reward">{{ VoucherPrintCopyWrite }} OFF</h2>
            <!-- <p class="minimum"><sup>*</sup>Minimum Purchase Rp100.000</p> -->
            <svg
              v-if="voucherCode"
              id="barcode"
              jsbarcode-format="CODE128"
              :jsbarcode-value="voucherCode"
              jsbarcode-textmargin="0"
              jsbarcode-fontoptions="bold"
              width="260"
            ></svg>
            <p class="greeting">To redeem, show this voucher to or cashier before making your purchase.</p>
            <p class="greeting">
              This voucher valid only for today<br />({{ today }})
              <template v-if="voucherEmail"> and eligible for {{ voucherEmail }}. </template>
              <template v-else>.</template>
            </p>
          </template>
        </div>
      </div>
    </GModal>
    <Footer v-if="!contentForPrint" position="home"></Footer>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers';
import VendingMachineTracker from '@/mixins/vending-machine-tracker';
import { get } from 'lodash';
import autotimeout from '@/mixins/auto-timeout';
// import { Carousel, Slide } from 'vue-carousel';

import { userActions } from '@/helpers/user-actions';

const moment = require('moment');
const JsBarcode = require('jsbarcode');
const Config = require('~/config/default.env').default;
const axios = require('axios');

export default {
  name: 'SocoBeautyStarFinish',
  components: {
    // Carousel,
    // Slide,
    Footer: () => import('@/components/vm-cola/snippets/footer/footer'),
    HomeBanners: () => import('@/components/vm-cola/snippets/home-banners/home-banners'),
    HeaderLogo: () => import('@/components/vm-cola/snippets/header/header-logo.vue'),
  },
  mixins: [helpers, VendingMachineTracker, autotimeout],
  data() {
    return {
      isModalVoucherOpen: false,
      isModalPrintOpen: false,
      stateIsPrinted: false,
      copyNotAllowed: "Oops! It's not a bad day, just try your luck next time.",
      processing: false,
      voucherCode: '',
      voucherEmail: '',
      minAmount: '',
      allowed: false,
      store_id: '',
      store_name: '',
      lowerScoreMode: false,
      timeout: 5 * 60 * 1000,
      interval: '',
      contentForPrint: false,
      isEmailSent: false,
      isVoucherGenerated: false,
    };
  },
  computed: {
    fullname() {
      if (this.$SO.size(this.$store.state.auth.userProfile)) {
        return this.$store.state.auth.userProfile.name;
      } else {
        return '';
      }
    },
    isAutoPlay() {
      return this.footerBanner.length > 1;
    },
    today() {
      if (this.$route.query.isvn) {
        return moment().add(1, 'days').format('DD/MM/YYYY');
      } else {
        return moment()
          .add(this.vendingMachineDevice ? 1 : 7, 'days')
          .locale('id')
          .format('DD MMMM YYYY');
      }
    },
    vendingMachineDevice() {
      return Cookies.get('vending_machine_device') == 'true';
    },
    voucherCopywriting() {
      let value = '';
      const arrVoucher = this.vouchers;
      let tempMinPoints = 0;
      for (let i = 0; i < arrVoucher.length; i++) {
        if (this.zits >= arrVoucher[i].min_point && arrVoucher[i].min_point > tempMinPoints) {
          tempMinPoints = arrVoucher[i].min_point;
          if (this.isVnSource) {
            value = `GIẢM ${arrVoucher[i].percentage}%`;
          } else {
            value = `${arrVoucher[i].percentage}% OFF`;
          }
        }
      }
      if (!value) {
        return false;
      }
      return value;
    },
    VoucherPrintCopyWrite() {
      let value = '-';
      const arrVoucher = this.vouchers;
      let tempMinPoints = 0;
      for (let i = 0; i < arrVoucher.length; i++) {
        if (this.zits >= arrVoucher[i].min_point && arrVoucher[i].min_point > tempMinPoints) {
          tempMinPoints = arrVoucher[i].min_point;
          value = `${arrVoucher[i].percentage}%`;
        }
      }
      return value;
    },
    zits() {
      return this.$store.state.pimplePop.zits || 0;
    },
    maximumDisc() {
      let value = 0;
      const arrVoucher = this.vouchers;
      for (let i = 0; i < arrVoucher.length; i++) {
        if (this.zits >= arrVoucher[i].min_point) {
          value = arrVoucher[i].percentage;
        }
      }
      return value * 10000;
    },
    isVnSource() {
      return this.$route.query.isvn;
    },
    beautyAtmData() {
      return this.$store.state.beautyAtmData;
    },
    vouchers() {
      let result = [];
      if (this.beautyAtmData?.vouchers?.length) {
        result = this.beautyAtmData.vouchers.filter((item) => item.reward_type !== 'no-reward');
      }
      return result;
    },
    footerBanner() {
      if (this.beautyAtmData?.banners?.length) {
        return this.beautyAtmData.banners;
      }
      return [];
    },
  },
  watch: {
    vouchers: {
      handler(newVal) {
        if (newVal.length) {
          this.getVoucher();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // JsBarcode("#barcode").init();
    this.$store.dispatch('getVmColaBanner', this);
    userActions(this);

    const host = window.location && window.location.hostname;
    if (host.indexOf('uat') > -1 || host.indexOf('localhost') > -1) {
      this.showTick = true;
    }
  },
  beforeDestroy() {
    this.$store.commit('setZits', 0);
    const parent = document.querySelector('html');
    if (parent != undefined) {
      parent.classList.remove('noscroll');
    }
    clearInterval(this.interval);
  },
  async created() {
    if (!this.beautyAtmData) {
      await this.getBeautyAtmData();
    }

    if (
      document.cookie.indexOf(
        `pimplePopUser${this.vendingMachineDevice ? '' : '-vmcola'}-${this.$store.state.auth.userProfile._id}=1`
      ) > -1
    ) {
      this.allowed = false;
      this.startTimer();
    }

    window.addEventListener('afterprint', (event) => {
      this.contentForPrint = false;
    });
  },
  methods: {
    getBeautyAtmData() {
      this.$store.dispatch('getBeautyAtmData');
    },
    closeModal() {
      this.isModalPrintOpen = false;
    },
    openModal() {
      this.isModalPrintOpen = true;
      setTimeout(() => {
        this.contentForPrint = true;

        setTimeout(() => {
          window.print();
          this.stateIsPrinted = true;
        }, 500);
      }, 2000);
    },
    toggleModalVoucher() {
      this.isModalVoucherOpen = !this.isModalVoucherOpen;
      const parent = document.querySelector('html');
      if (this.isModalVoucherOpen == true) {
        if (parent != undefined) {
          parent.classList.add('noscroll');
        }
      } else {
        if (parent != undefined) {
          parent.classList.remove('noscroll');
        }
      }
    },
    limitUser() {
      if (!this.$store.state.auth.userProfile) {
        return false;
      }
      const m = moment();
      // sociolla-admin expects date in utc > 23:59:29(id)== 16:59:59(utc)
      const endDateDay = new Date(m.get('year'), m.get('month'), m.get('date'), 16, 59, 59);
      const cookiename = `pimplePopUser${this.vendingMachineDevice ? '' : '-vmcola'}-${
        this.$store.state.auth.userProfile._id
      }=1`;
      if (document.cookie.indexOf(cookiename) < 0) {
        document.cookie = `${cookiename}; expires=${endDateDay}`;
      }
    },

    goToShopping() {
      this.$router.push('/vmcola/products');
    },

    sendEmail() {
      if (this.isEmailSent) {
        return;
      }

      const payload = {
        user_email: this.$store.state.auth.userProfile ? this.$store.state.auth.userProfile.email : '',
        discount: this.voucherCopywriting,
        expired_at: this.today,
        voucherCode: this.voucherCode,
        minAmount: this.minAmount,
        is_vmcola: !this.vendingMachineDevice,
      };

      let tokenStr = Cookies.get('token');
      if (tokenStr == undefined || !tokenStr) {
        tokenStr = this.$store.state.auth.authToken;
      }

      const headers = {
        Authorization: `Bearer ${tokenStr}`,
      };

      axios
        .post(`${Config.MS_SOCIOLLA_PUBLIC_API_URL}/vending-machine/send-email`, payload, {
          headers: headers,
        })
        .then((response) => {
          if (response.status == 200) {
            this.isEmailSent = true;
            // this.$toasted.global.show('Email Sent Succesfully!');
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.global.error('Error in Sending Mail , Please Try Again');
          // this.$router.push(this.isVnSource ? '/vending-machine-vn/home?isvn=true' : '/vending-machine/home');
        });
    },

    async backHome() {
      try {
        await this.postTracker('finish', this.zits, this.voucherCode);
        if (this.isVnSource) {
          this.$router.push('/vending-machine-vn/home?isvn=true');
        } else {
          const path = (await Cookies.get('vending_machine_device')) == 'true' ? 'home' : 'vmcola';
          if (path === 'vmcola') {
            this.$router.push('/vmcola/home');
          } else {
            this.$router.push('/vending-machine/' + path);
          }
        }
      } catch (err) {
        console.log(err);
        if (this.isVnSource) {
          this.$router.push('/vending-machine-vn/home?isvn=true');
        } else {
          const path = (await Cookies.get('vending_machine_device')) == 'true' ? 'home' : 'vmcola';
          if (path === 'vmcola') {
            this.$router.push('/vmcola/home');
          } else {
            this.$router.push('/vending-machine/' + path);
          }
        }
      }
    },
    async getVoucher() {
      let value = 0;
      const m = moment();
      const arrVoucher = this.vouchers;
      let tempMinPoints = 0;
      for (let i = 0; i < arrVoucher.length; i++) {
        if (this.zits >= arrVoucher[i].min_point && arrVoucher[i].min_point > tempMinPoints) {
          tempMinPoints = arrVoucher[i].min_point;
          value = arrVoucher[i].percentage;
        }
      }
      if (value <= 0) {
        return false;
      }

      const cookieUser = `pimplePopUser${this.vendingMachineDevice ? '' : '-vmcola'}-${
        this.$store.state.auth.userProfile._id
      }=1`;
      if (document.cookie.indexOf(cookieUser) > -1) {
        return false;
      }
      if (Cookies.get('store_detail') && Cookies.get('store_detail') != undefined) {
        try {
          const json_data = JSON.parse(Cookies.get('store_detail'));
          this.store_id = json_data._id;
          this.store_name = json_data.name;
          this.store_alias = json_data.alias;
        } catch (err) {}
      }
      const regex = '%';
      let prefixVn = 'BBB';

      if (this.VoucherPrintCopyWrite) {
        const replace = this.VoucherPrintCopyWrite.search(regex);
        if (replace >= 0) {
          prefixVn = 'BBB' + this.VoucherPrintCopyWrite.replace(regex, '');
        }
      }

      if (!this.processing) {
        this.processing = true;
        // sociolla-admin expects date in utc > 23:59:29(id)== 16:59:59(utc)
        const date = this.isVnSource
          ? `${m.add(1, 'days').format('YYYY-MM-DD')} 16:59:59`
          : `${m.format('YYYY-MM-DD')} 16:59:59`;
        let tokenStr = Cookies.get('token');
        if (tokenStr == undefined || !tokenStr) {
          tokenStr = this.$store.state.auth.authToken;
        }

        const payload = {
          voucher_name:
            'Soco Beauty Star Voucher ' +
            (this.$store.state.auth.userProfile ? this.$store.state.auth.userProfile.email : ''),
          voucher_description:
            'Soco Beauty Star Voucher ' +
            (this.$store.state.auth.userProfile ? this.$store.state.auth.userProfile.email : ''),
          voucher_value: value,
          minimum: this.isVnSource ? 0 : 100000,
          maximum_discount: this.maximumDisc,
          validate_date: date,
          super_voucher: 1,
          free_shipping: 0,
          voucher_type: 'percentage',
          prefix: this.isVnSource ? prefixVn : (await Cookies.get('vending_machine_device')) == 'true' ? 'LAVM' : 'VM',
          email: this.$store.state.auth.userProfile ? this.$store.state.auth.userProfile.email : '',
          user_id: this.$store.state.auth.userProfile ? this.$store.state.auth.userProfile._id : '',
          points: this.zits,
          store_id: this.store_id,
          store_name: this.store_name,
          store_alias: this.store_alias,
          applied_game_rule_id: this.beautyAtmData._id,
          is_big_bang: !this.vendingMachineDevice,
          type_of_voucher: 'product_cost',
        };

        const headers = {
          Authorization: `Bearer ${tokenStr}`,
        };

        if (this.isVnSource) {
          headers['SOC-PLATFORM'] = 'sociolla-vn-pos';
          headers['source'] = 'store_kota';
        }

        this.$MS_SOCO_PUBLIC_API_URL
          .post(`customer/create/voucher`, payload, {
            headers: headers,
          })
          .then((response) => {
            this.processing = false;
            if (response.status == 200) {
              this.voucherCode = response.data.data.code;
              this.voucherEmail = response.data.data.rules.users ? response.data.data.rules.users[0].email : '';
              this.minAmount = response.data.data.rules.order ? response.data.data.rules.order.min_amount : '';

              this.allowed = true;

              setTimeout(() => {
                JsBarcode('#barcode').init();
              }, 400);
              this.limitUser();
              this.startTimer();

              this.sendEmail();
            }
          })
          .catch((err) => {
            console.log(err);

            this.allowed = false;
            this.processing = false;
            const errMessage = get(err, 'response.data.message', null);
            if (errMessage && errMessage === 'Voucher already created for user.') {
              this.isVoucherGenerated = true;
              return;
            }

            this.$toasted.global.error(this.isVnSource ? 'Vui lòng thử lại.' : 'Please try again later.');
            this.$router.push(this.isVnSource ? '/vending-machine-vn/home?isvn=true' : '/vending-machine/home');
          });
      }
    },
    startTimer() {
      this.interval = setInterval(() => {
        const path = Cookies.get('vending_machine_device') == 'true' ? 'home' : 'vmcola';
        this.$router.push(
          this.isVnSource
            ? '/vending-machine-vn/home?isvn=true'
            : path !== 'vmcola'
            ? '/vending-machine/' + path
            : '/vmcola/home'
        );
        this.postTracker('finish', this.zits, this.voucherCode);
        clearInterval(this.interval);
      }, this.timeout);
    },
  },
};
</script>

<style lang="scss" scoped>
.bbb-finish {
  min-height: 100vh;
  background: #000 url('/static/img/vmcola-bg-pink.svg') no-repeat top center;
  background-size: 100% auto;
  padding: 100px 0 0;
  box-sizing: border-box;

  .sociolla-logo {
    width: 350px;
  }

  .game-over {
    margin-top: 80px;

    .failed {
      font-family: 'Lato', sans-serif;
      color: #b32656;
      font-size: 68px;
      font-weight: 700;
      line-height: 74px;
      text-align: center;
    }

    .title {
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      color: #b32656;
      font-size: 65px;
      line-height: 60px;
      letter-spacing: 1px;
    }
  }
}
.title {
  display: block;
  margin: 0 auto 40px;
}
.title-vn {
  margin: 0 auto 126px;
}
.score-vn {
  margin: 0 auto 40px !important;
  font-family: 'SVN-brandontext-bold', sans-serif !important;
}
.score {
  width: 420px;
  height: 420px;
  background: transparent url('/static/img/icons/icon-soco-point-pink.svg') no-repeat center;
  background-size: 380px 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font: bold 40px/42px 'Lato', sans-serif;
  color: #000;
}

.score-failed {
  position: relative;
  margin: 0 auto 30px !important;
}
.score p {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: #fff;
  font-size: 120px;
  position: relative;
  left: -10px;
  top: -35px;
}
.bbb-pages {
  .box-congrats {
    background: transparent;
    height: auto;
    padding: 0;
  }
  .button-browse-shop,
  .button-print-voucher {
    background: #fff;
    color: #000;
    border: none;
    width: 400px;
    border-radius: 45px;

    &:disabled {
      opacity: 0.5;
    }
  }

  .button-print-voucher {
    margin-top: 20px;

    &.black {
      color: #fff;
      background: #000;
      margin-bottom: 0;

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .button-browse-shop {
    margin-top: 15px;
    font: 32px/44px 'Lato', sans-serif;
    font-weight: 700;
    background: #000;
    color: #fff;
    height: 93px;

    &.white {
      background: #fff;
      color: #000;
    }
  }
  .box-congrats__heading {
    color: #d51d6e;
  }
  .box-congrats__text {
    color: #fff;
  }
  .coupon-sbs {
    width: 510px;
    height: 350px;
    background: transparent url('/static/img/soco-beauty-star/icon-voucher-win-pink.svg') no-repeat center;
    background-size: 510px 350px;
  }
  .coupon-sbs-text {
    color: #000;
    font: normal 56px/64px 'Lato', sans-serif;
    font-weight: 700;
  }
  .coupon-sbs-title {
    font: normal 86px/84px 'Lato', sans-serif;
    font-size: 65px;
    color: #fff;
    font-weight: bold;
  }
  .coupon-sbs-small {
    font: normal 22px/20px 'Lato', sans-serif;
    font-size: 18px;
    color: #fff;
  }
  .coupon-sbs-2 {
    color: #000;
    font: normal 30px/40px 'Lato', sans-serif;
  }
  .coupon-vn {
    width: 638px;
    height: 215px;
    padding-top: 37px;
    background: transparent url('/static/img/soco-beauty-star/voucher-shape-sbs.png') no-repeat center;
    background-size: cover;
    margin: 0 auto 61px auto;
    padding: 43px 73px 43px 128px;
  }
  .sbs-text-title {
    color: #000;
    font-size: 38px;
    font-family: 'Lato', sans-serif;
    padding: 0 30px 30px 30px;

    &.voucher-and-point-title {
      width: 100%;
      height: 70px;
      margin-top: 50px;

      background-repeat: no-repeat;
      background-position: center;

      font-weight: bold;
      font-size: 68px;
      color: #b32656;
    }

    &.voucher-and-point-subtitle {
      margin-top: 50px;
      font-family: 'Lato', sans-serif;
      font-size: 38px;
      font-weight: 500;
      line-height: 50px;
      letter-spacing: 0.03em;
      text-align: center;
    }
  }
  .sbs-finish-footer {
    background: #000;
    height: 700px;
    width: 100%;
    margin-top: 100px;
    bottom: 0;
    position: absolute;
    border-radius: 50px 50px 0 0;
  }
  .item-slide img {
    width: 866px;
    height: 489px;
  }
}

.clock__box {
  position: relative;
}
.clock__position {
  position: absolute;
  left: 0px;
  top: -5px;
}

.coupon__reward {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  position: relative;
  top: 115px;
}

.reward-container {
  display: flex;
  width: 100%;
  margin: 0 auto;
  gap: 30px;
  justify-content: center;
}

.vn {
  font-family: 'Lato', sans-serif !important;
}

@media print {
  .title,
  .score {
    display: none;
  }
}
</style>
